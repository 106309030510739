const env = process.env.REACT_APP_CUSTOM_ENV || process.env.NODE_ENV


const workshopAPIHosts : {[key: string] : string} = {
  development: 'https://sandbox.warsztat.integrator.org.pl',
  testing: 'https://sandbox.warsztat.integrator.org.pl',
  'pre-production' : 'https://warsztat.integrator.org.pl',
  production: 'https://warsztat.integrator.org.pl'
}

const APIHosts : {[key: string] : string} = {
  development: "https://sandbox.system.integrator.org.pl",

  //local 1
  // development: "http://192.168.1.116:3003",
 
  //local 2
  // development: "http://192.168.17.102:3003",
  
  testing: "https://sandbox.system.integrator.org.pl",
  "pre-production": "https://pre.system.integrator.org.pl",
  production : "https://system.integrator.org.pl"
};

const RailsHosts : {[key: string] : string} = {
  development: "http://localhost:3003",
  
  testing: "https://admin.sandbox.integrator.org.pl",
  production : "https://admin.system.integrator.org.pl"
};

const IntegratorOrgHosts : {[key: string] : string} = {

  development: "http://sandbox.integrator.org.pl/",
  testing: "http://sandbox.integrator.org.pl/",
  "pre-production": "http://integrator.org.pl/",
  production: "http://integrator.org.pl/",
}

export const API_BASE_URL = APIHosts[env || 'development'] + "/api/v1";
export const ADMIN_API_URL = APIHosts[env || 'development'] + "/api/v1";
export const RAILS_HOST = RailsHosts[env || 'development'];
export const WORKSHOP_API_URL = workshopAPIHosts[env || 'development'] + "/api";
export const TEST_VAR = env;
export const FRONT_DATE = '2024-05-25'

